const header = document.getElementById("header");

const navBtn = $('#navBtn');
const navMenu = $('#navMenu');

let menuOpenFlg = 0;
let scrTopFlg = 0;
let scrollPosition;

export function headerFunc() {
  navBtn.on('click', function () {
    navBtn.toggleClass('isOpen');
    if (navBtn.hasClass('isOpen')) {
      scrollPosition = $(window).scrollTop();
      menuOpenFlg = 1;
      navMenu.addClass('isShow');
      header.classList.remove('changeColor');
      $('.headerContents').addClass('isShow');
      $('body').addClass('fixedBody').css({
        'top': -scrollPosition
      });
    } else {
      menuOpenFlg = 0;
      navMenu.removeClass('isShow');
      $('.headerContents').removeClass('isShow');
      $('body').removeClass('fixedBody').css({
        'top': 0
      });
      window.scrollTo(0, scrollPosition);
      if (scrTopFlg == 1) {
        header.classList.add('changeColor');
      }
    }
  });
}

export function headerScrFunc() {
  console.log('スクロールで呼ばれます。');
}