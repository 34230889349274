//トップページ　キービジュアルスライダー
import Player from '@vimeo/player';
import Lenis from '@studio-freight/lenis'
import PerfectScrollbar from 'perfect-scrollbar';

export function idxFunc() {
  let indexTop = $('#indexTop').length;

  if (indexTop) {
    let resizecheck = $('#responsibleCheck').width();
    let resizecheck2 = 0;
    
    var ps = new PerfectScrollbar('.newsList');
    let psHeight = $('.ps__thumb-y').height();

    var finishResizeEvent = function() {
      var setTimer = false;
      $(window).on('resize', function () {
        if (setTimer !== false) {
          clearTimeout(setTimer);
        }
        setTimer = setTimeout(() => {
          ps.update();
        }, 200);
      });
    };
    finishResizeEvent();

    if (resizecheck == '2' && resizecheck != resizecheck2) { //SPの時のみ
      resizecheck2 = 2;
    } else if (resizecheck == '1' && resizecheck != resizecheck2) {
      let easeOutQuart = t => 1 - Math.pow(1 - t, 4);
      let lenis = new Lenis({
        lerp: 0.2, // 慣性の強さ
        duration: 1, // スクロールアニメーションの時間
        easing: easeOutQuart, // イージング関数
      });
      function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
      }
      requestAnimationFrame(raf);
      lenis.start();

      resizecheck2 = 1;
    }
  }
}

export function movieSwitchFunc() {
  let indexTop = $('#indexTop').length;

  if (indexTop) {
    let resizecheck = $('#responsibleCheck').width();
    let resizecheck2 = 0;
    let movieId;

    if (resizecheck == '2' && resizecheck != resizecheck2) { //SPの時のみ
      movieId = 870988168;
      resizecheck2 = 2;
    } else if (resizecheck == '1' && resizecheck != resizecheck2) {
      movieId = 870988168;
      resizecheck2 = 1;
    }

    var mainOptionsSwitch = {
      id: movieId, // VimeoのID
      background: 1,
      autoplay: 0,
      muted: true,
      autopause: true
    };

    //var mainPlayerSwitch = new Player('mainMovie', mainOptionsSwitch);
    var v = document.getElementById('mainviewVideo');
  }
}

export function mainMovieFunc() {
  let indexTop = $('#indexTop').length;

  if (indexTop) {
    //var mainPlayer = new Player('mainMovie');
    var v = document.getElementById('mainviewVideo');

    setTimeout(function(){
      //mainPlayer.play();
      v.play();
    },100);

    var playerObj;
    //playerObj = mainPlayer.play();
    playerObj = v.play();

    if (playerObj) {
      playerObj.then(() => {
        setTimeout(() => {
          playerObj
        }, playerObj.duration * 100);
      }).catch((e) => {
      });
    }
  }
}